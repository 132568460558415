export default {
  'role.name': 'Quản lý vai trò',
  'role.userManager': 'Quản lý người dùng',
  'roles.name': 'Vai trò',
  'roles.roleName': 'Tên vai trò',
  'roles.rolePermissions': 'Quyền',
  'roles.permissionCodes': 'Quyền',
  'roles.delete.title': 'Xóa vai trò',
  'roles.delete.content': 'Bạn có muốn xóa vai trò này?',
  'roles.createdAt': 'Ngày tạo',
  'roles.action': 'Thao tác',
  'Per.Users.Create': 'Thêm user',
  'Per.Users.Delete': 'Xoá user',
  'Per.Users.Edit': 'Sửa user',
  'Per.Users.Export': 'Xuất user',
  'Per.Users.View': 'Xem chi tiết user',
  'roles.create': 'Tạo vai trò',
  'roles.information': 'Thông tin vai trò',
  'roles.update': 'Chỉnh sửa vai trò',
  'Mes.Users.Role.UpdateSuccessfully': 'Cập nhật vai trò thành công',
  'Mes.Roles.Add.Successfully': 'Thêm vai trò thành công',

  'common.checkAll': 'Tất cả',
  'roles.add.name': 'Thêm vai trò',
  'roles.edit.name': 'Chỉnh sửa vai trò',
  'roles.info.name': 'Thông tin vai trò',
  'role.confirm.title.delete': 'Bạn có muốn xóa vai trò không ?',

  'permission.BatchsView': 'Xem chi tiết thông tin Quản lý đợt quay thưởng',

  'permission.CodeSubmitsExport': 'Xuất file lịch sử đổi mã',

  'permission.CodeSubmitsView': 'Xem chi tiết thông tin lịch sử đổi mã',

  'permission.LuckyDrawsRevokePrize': 'Thu hồi giải thưởng',

  'permission.LuckyDrawsSendSms': 'Gửi tin nhắn sms',

  'permission.LuckyDrawsUpdate': 'Cập nhật thông tin Rút thăm may mắn',

  'permission.LuckyDrawsView': 'Xem chi tiết thông tin Rút thăm may mắn',

  'permission.ModeSettingsCreate': 'Thêm tỷ lệ',

  'permission.ModeSettingsDelete': 'Xóa tỷ lệ',

  'permission.ModeSettingsUpdate': 'Cập nhật thông tin tỷ lệ',

  'permission.ModeSettingsView': 'Xem chi tiết thông tin tỷ lệ',

  'permission.PrizesCreate': 'Thêm giải thưởng',

  'permission.PrizesDelete': 'Xóa giải thưởng',

  'permission.PrizesUpdate': 'Cập nhật thông tin giải thưởng',

  'permission.PrizesView': 'Xem chi tiết thông tin giải thưởng',

  'permission.RedeemCodesBlock': 'Khóa mã',

  'permission.RedeemCodesImport': 'Nhập mã',

  'permission.RedeemCodesView': 'Xem chi tiết thông tin mã',

  'permission.RedeemsSpin': 'Quay thưởng',

  'permission.RolesCreate': 'Thêm vai trò',

  'permission.RolesDelete': 'Xóa vai trò',

  'permission.RolesUpdate': 'Cập nhật thông tin vai trò',

  'permission.RolesView': 'Xem chi tiết thông tin vai trò',

  'permission.RoundsCreate': 'Thêm Round',

  'permission.RoundsDelete': 'Xóa Round',

  'permission.RoundsUpdate': 'Cập nhật thông tin Round',

  'permission.RoundsView': 'Xem chi tiết thông tin Round',

  'permission.UsersCreate': 'Thêm người dùng',

  'permission.UsersDelete': 'Xóa người dùng',

  'permission.UsersUpdate': 'Cập nhật thông tin người dùng',

  'permission.UsersView': 'Xem chi tiết thông tin người dùng',

  'permission.CodeSubmitLogsView': 'Xem chi tiết thông tin Quản lý Nhật ký mã',

  'permission.TopUpsReTopUp': 'ReTopUp',

  'permission.LuckyDrawsImport': 'Nhập danh sách quay trúng thưởng',

  'permission.LuckyDrawsExport': 'Xuất danh sách quay trúng thưởng',

  'permission.CodeSubmitLogsExport': 'Xuất danh sách nhật ký mã',

  'role.Careers': 'Ngành',

  'permission.CareersCreate': 'Tạo Ngành',

  'permission.CareersDelete': 'Xóa Ngành',

  'permission.CareersSearch': 'Xem danh sách Ngành',

  'permission.CareersUpdate': 'Cập nhật Ngành',

  'permission.CareersView': 'Xem chi tiết Ngành',

  'role.Certificates': 'Chứng chỉ',

  'permission.CertificatesCreate': 'Tạo Chứng chỉ',

  'permission.CertificatesDelete': 'Xóa Chứng chỉ',

  'permission.CertificatesSearch': 'Xem danh sách Chứng chỉ',

  'permission.CertificatesUpdate': 'Cập nhật Chứng chỉ',

  'permission.CertificatesView': 'Xem chi tiết Chứng chỉ',

  'role.Clubs': 'Chi bộ',

  'permission.ClubsCreate': 'Tạo Chi bộ',

  'permission.ClubsDelete': 'Xóa Chi bộ',

  'permission.ClubsSearch': 'Xem danh sách Chi bộ',

  'permission.ClubsUpdate': 'Cập nhật Chi bộ',

  'permission.ClubsView': 'Xem chi tiết Chi bộ',

  'permission.ClubsCreateMember': 'Tạo Đội viên',

  'permission.ClubsCreatePartner': 'Tạo đối tác Chi bộ',

  'permission.ClubsInvite': 'Mời thành viên Chi bộ',

  'permission.ClubsChangeClub': 'Thay đổi Chi bộ',

  'role.Contacts': 'Liên hệ',

  'permission.ContactsUpdate': 'Cập nhật Liên hệ',

  'role.Enterprises': 'Doanh nghiệp',

  'permission.EnterprisesCreate': 'Tạo Doanh nghiệp',

  'permission.EnterprisesDelete': 'Xóa Doanh nghiệp',

  'permission.EnterprisesSearch': 'Xem danh sách Doanh nghiệp',

  'permission.EnterprisesUpdate': 'Cập nhật Doanh nghiệp',

  'permission.EnterprisesView': 'Xem chi tiết Doanh nghiệp',

  'role.ExecutiveCommittees': 'Ban chấp hành',

  'permission.ExecutiveCommitteesCreate': 'Tạo Ban chấp hành',

  'permission.ExecutiveCommitteesDelete': 'Xóa Ban chấp hành',

  'permission.ExecutiveCommitteesSearch': 'Xem danh sách Ban chấp hành',

  'permission.ExecutiveCommitteesUpdate': 'Cập nhật Ban chấp hành',

  'permission.ExecutiveCommitteesView': 'Xem chi tiết Ban chấp hành',

  'role.Fees': 'Hội phí',

  'permission.FeesCreate': 'Tạo Hội phí',

  'permission.FeesDelete': 'Xóa Hội phí',

  'permission.FeesSearch': 'Xem danh sách Hội phí',

  'permission.FeesUpdate': 'Cập nhật Hội phí',

  'permission.FeesView': 'Xem chi tiết Hội phí',

  'role.Fields': 'Lĩnh vực',

  'permission.FieldsCreate': 'Tạo Lĩnh vực',

  'permission.FieldsDelete': 'Xóa Lĩnh vực',

  'permission.FieldsSearch': 'Xem danh sách Lĩnh vực',

  'permission.FieldsUpdate': 'Cập nhật Lĩnh vực',

  'permission.FieldsView': 'Xem chi tiết Lĩnh vực',

  'role.Markets': 'Thị trường',

  'permission.MarketsCreate': 'Tạo Thị trường',

  'permission.MarketsDelete': 'Xóa Thị trường',

  'permission.MarketsSearch': 'Xem danh sách Thị trường',

  'permission.MarketsUpdate': 'Cập nhật Thị trường',

  'permission.MarketsView': 'Xem chi tiết Thị trường',

  'role.Medias': 'Tài liệu',

  'permission.MediasCreate': 'Tạo tài liệu',

  'permission.MediasDelete': 'Xóa tài liệu',

  'role.Members': 'Hội viên',

  'permission.MembersCreate': 'Tạo Hội viên',

  'permission.MembersDelete': 'Xóa Hội viên',

  'permission.MembersSearch': 'Xem danh sách Hội viên',

  'permission.MembersUpdate': 'Cập nhật Hội viên',

  'permission.MembersView': 'Xem chi tiết Hội viên',

  'role.Notifications': 'Thông Báo',

  'permission.NotificationsCreate': 'Tạo Thông Báo',

  'permission.NotificationsDelete': 'Xóa Thông Báo',

  'permission.NotificationsSearch': 'Xem danh sách Thông Báo',

  'permission.NotificationsUpdate': 'Cập nhật Thông Báo',

  'permission.NotificationsView': 'Xem chi tiết Thông Báo',

  'role.Organizations': 'Tổ chức',

  'permission.OrganizationsCreate': 'Tạo Tổ chức',

  'permission.OrganizationsDelete': 'Xóa Tổ chức',

  'permission.OrganizationsSearch': 'Xem danh sách Tổ chức',

  'permission.OrganizationsUpdate': 'Cập nhật Tổ chức',

  'permission.OrganizationsView': 'Xem chi tiết Tổ chức',

  'role.Partners': 'Đối tác',

  'permission.PartnersCreate': 'Tạo Đối tác',

  'permission.PartnersDelete': 'Xóa Đối tác',

  'permission.PartnersSearch': 'Xem danh sách Đối tác',

  'permission.PartnersUpdate': 'Cập nhật Đối tác',

  'permission.PartnersView': 'Xem chi tiết Đối tác',

  'role.Ranks': 'Hạng thành viên',

  'permission.RanksSearch': 'Xem danh sách Hạng thành viên',

  'permission.RanksUpdate': 'Cập nhật Hạng thành viên',

  'permission.RanksView': 'Xem chi tiết Hạng thành viên',

  'role.Roles': 'Vai trò',

  'permission.RolesExport': 'Xuất Vai trò',

  'permission.RolesSearch': 'Xem danh sách Vai trò',

  'role.Schedules': 'Lịch',

  'permission.SchedulesCancel': 'Hủy Lịch',

  'permission.SchedulesCreate': 'Tạo Lịch',

  'permission.SchedulesDelete': 'Xóa Lịch',

  'permission.SchedulesSearch': 'Xem danh sách Lịch',

  'permission.SchedulesUpdate': 'Cập nhật Lịch',

  'permission.SchedulesView': 'Xem chi tiết Lịch',

  'role.Sponsors': 'Đóng góp',

  'permission.SponsorsCreate': 'Tạo đóng góp',

  'permission.SponsorsDelete': 'Xóa đóng góp',

  'permission.SponsorsSearch': 'Xem danh sách đóng góp',

  'permission.SponsorsUpdate': 'Cập nhật đóng góp',

  'permission.SponsorsView': 'Xem chi tiết đóng góp',

  'role.TargetCustomers': 'Khách hàng mục tiêu',

  'permission.TargetCustomersCreate': 'Tạo Khách hàng mục tiêu',

  'permission.TargetCustomersDelete': 'Xóa Khách hàng mục tiêu',

  'permission.TargetCustomersSearch': 'Xem danh sách Khách hàng mục tiêu',

  'permission.TargetCustomersUpdate': 'Cập nhật Khách hàng mục tiêu',

  'permission.TargetCustomersView': 'Xem chi tiết Khách hàng mục tiêu',

  'role.Users': 'Người dùng',

  'permission.UsersExport': 'Xuất file người dùng',

  'permission.UsersSearch': 'Xem danh sách người dùng',

  'role.Represents': 'Cấp ủy',

  'permission.RepresentsCreate': 'Tạo Cấp ủy ',

  'permission.RepresentsDelete': 'Xóa Cấp ủy',

  'permission.RepresentsSearch': 'Xem danh sách Cấp ủy',

  'permission.RepresentsUpdate': 'Cập nhật Cấp ủy',

  'permission.RepresentsView': 'Xem chi tiết Cấp ủy',

  'permission.ClubsDeleteMember': 'Xóa Đội viên',

  'permission.ClubsDeletePartner': 'Xóa Đối tác Chi bộ',

  'permission.ClubsUpdateMember': 'Cập nhật Đội viên',

  'permission.ClubsUpdatePartner': 'Cập nhật Đối tác Chi bộ',

  'permission.ClubsImportMember': 'Import Đội viên',
  'permission.ClubsImportPartner': 'Import Đối tác',
  'permission.MembersImport': 'Import Đội viên',
  'permission.ExecutiveCommitteesImport': 'Import Ban chấp hành',
  'permission.PartnersImport': 'Import Đối tác',
  'permission.RepresentsImport': 'Import Cấp ủy',

  // Action
  'role.Activities': 'Hoạt động',
  'permission.ActivitiesCreate': 'Tạo hoạt động',
  'permission.ActivitiesCreateSub': 'Tạo hoạt động con',
  'permission.ActivitiesDelete': 'Xóa hoạt động',
  'permission.ActivitiesDeleteSub': 'Xóa hoạt động con',
  'permission.ActivitiesSearch': 'Xem danh sách hoạt động',
  'permission.ActivitiesUpdate': 'Cập nhật hoạt động',
  'permission.ActivitiesUpdateSub': 'Cập nhật hoạt động con',
  'permission.ActivitiesView': 'Xem chi tiết hoạt động',

  // Merit Badges
  'role.MeritBadges': 'Chuyên hiệu',
  'permission.MeritBadgesCreate': 'Tạo chuyên hiệu',
  'permission.MeritBadgesDelete': 'Xóa chuyên hiệu',
  'permission.MeritBadgesSearch': 'Xem danh sách chuyên hiệu',
  'permission.MeritBadgesUpdate': 'Cập nhật chuyên hiệu',
  'permission.MeritBadgesView': 'Xem chi tiết chuyên hiệu',

  // Reports
  'role.Reports': 'Báo cáo',
  'permission.ReportsCreate': 'Tạo báo cáo',
  'permission.ReportsDelete': 'Xóa báo cáo',
  'permission.ReportsSearch': 'Xem danh sách báo cáo',
  'permission.ReportsView': 'Xem chi tiết báo cáo',

  // Rewards
  'role.Rewards': 'Khen thưởng',
  'permission.RewardsCreate': 'Tạo khen thưởng',
  'permission.RewardsDelete': 'Xóa khen thưởng',
  'permission.RewardsSearch': 'Xem danh sách khen thưởng',
  'permission.RewardsView': 'Xem chi tiết khen thưởng',

  // Suggests
  'role.Suggests': 'Đề xuất',
  'permission.SuggestsCreate': 'Tạo đề xuất',
  'permission.SuggestsDelete': 'Xóa đề xuất',
  'permission.SuggestsSearch': 'Xem danh sách đề xuất',
  'permission.SuggestsView': 'Xem chi tiết đề xuất',
  'permission.SuggestsApprove': 'Duyệt đề xuất',
  'permission.SuggestsReject': 'Từ chối đề xuất',

  // Change class
  'permission.ClubsChangeClass': 'Chuyển chi đội',

  // Feedback
  'role.Feedbacks': 'Phản ánh kiến nghị',
  'permission.FeedbacksReply': 'Phản hồi',
  'permission.FeedbacksSearch': 'Xem danhh sách Phản ánh',
  'permission.FeedbacksView': 'Xem chi tiết Phản ánh',

  // Statistics
  'role.Statistics': 'Báo cáo tuổi Đảng',
  'permission.StatisticsAgeReport': 'Xem báo cáo tuổi Đảng',
};
